import { router } from 'lib/router'
const { routes } = router

/**
 * Verifies if user is logged in.
 * @function
 * @usage `import { verifyLoggedIn } from 'helpers/authenticationHelper'`
 * @param {object} ctx - Current context: Usually `ctx` from SS and `this` from CS
 */
const getCurrentPath = (ctx) => {
  return ctx.req ? ctx.req.url : ctx.pathname
}

const redirect = (isLoggedIn, currentPath) => {
  if (!isLoggedIn) {
    const query = currentPath.split('?')[1]
    if (query) {
      const identifier = query.split('=')[0]
      if (identifier === 'referral_identifier') {
        return 0
      }
    }
    return routes.login.basePath
  }

  switch (currentPath) {
  case '/':
  case routes.login.basePath:
    return routes.home.basePath
  case routes.logout.basePath:
    return null
  default:
    return null
  }
}

export const verifyLoggedIn = (ctx) => {
  const { authStore } = ctx.rootStore
  const isLoggedIn = authStore.isLoggedIn

  if (ctx) {
    const currentPath = getCurrentPath(ctx)
    const redirectTo = redirect(isLoggedIn, currentPath)

    // ? We're already at the location
    if (redirectTo && currentPath !== redirectTo) {
      router.replace(redirectTo, {}, ctx)
    }
  }

  return authStore && authStore.authToken
}

export const goToLoginAndReturnToCurrentPath = () => {
  router.push(`${router.routes.login.basePath}?redirect_to=${window.location.pathname}`)
}

export const goToLoginAndReturnToPath = (ctx, path) => {
  router.push(`${router.routes.login.basePath}?redirect_to=${path}`)
}

import React, { useState, useEffect } from 'react'
import Modal from '~/components/Modal'
import { isAndroid, isIOS } from 'react-device-detect'

import * as style from './styles'

const DownloadAppModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(isAndroid || isIOS)
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      backgroundColor='blue'
    >
      <img src='/static/download-app-banner.png' alt='download app' css={style.downloadImg} />

      <div css={style.downloadDiv}>
        <a href='https://apps.apple.com/us/app/crowdmouth-for-creators/id1556232080' target='_blank' rel='noreferrer'>
          <img src='/static/download-ios.svg' />
        </a>

        <a href='https://play.google.com/store/apps/details?id=com.crowdmouth.creator' target='_blank' rel='noreferrer'>
          <img src='/static/download-android.svg' />
        </a>
      </div>
    </Modal>
  )
}

export default DownloadAppModal

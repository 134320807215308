import { Component } from 'react'
import Router from 'next/router'
import { verifyLoggedIn } from 'helpers/authenticationHelper'
import { RouteHelper } from '@sowlutions-tech/next/common/helpers'

class Page extends Component {
  static async serverSideCall(ctx) {
    return {
      loggedIn: ctx && verifyLoggedIn(ctx),
    }
  }

  updateUrl(newParams) {
    if (!this.props.router) {
      return
    }

    let params = this.props.router.query

    params = {
      ...params,
      ...newParams,
    }

    const redirectTo = RouteHelper.toQueryString(this.props.router.pathname, params)
    Router.replace(redirectTo)
  }
}

export default Page

import React, { useEffect, useState } from 'react'
import Page from '~/pages/page'
import { inject, observer } from 'mobx-react'
import { router } from '~/lib/router'

import Icon from '~/components/Icon'
import Title from '~/components/Title'
import LoginContainer from '~/components/LoginContainer'
import DownloadAppModal from '~/components/DownloadAppModal'

import * as style from './styles'

const LoginPage = ({ rootStore, redirectTo }) => {
  const { usersStore, notificationsStore, authStore } = rootStore
  const [, setIsLoading] = useState(false)
  const [id, setId] = useState('')

  const redirectPage = (page) => {
    if (page === 'tunecore') {
      router.replace(router.routes.tuneCore)
      return
    }
    if (redirectTo) {
      router.replace(redirectTo)
    } else {
      router.replace(router.routes.home)
    }
  }

  const logoutAndReload = () => {
    authStore.logout()
    window.location.reload()
  }

  const setAsCreator = async () => {
    setIsLoading(true)
    const newUserData = await usersStore.api().creatorCreatorRole()
    if (newUserData.success) {
      authStore.setCurrentUser(newUserData.data)
      redirectPage()
    } else {
      logoutAndReload()
    }
  }

  const handleSuccess = async (data) => {
    const shouldValidateCreator = !Boolean(redirectTo)
    authStore.setCurrentUser(data)

    if (shouldValidateCreator && !data.isCreator) {
      setAsCreator()
    } else {
      notificationsStore.addNotification({
        type: 'success',
        message: 'Logged in successfully! Redirecting..',
      })
    }
    if (!data?.premiumCreator) {
      redirectPage('tunecore')
      return
    } else {
      redirectPage()
    }
  }

  useEffect(() => {
    // Check if document.referrer is available
    const queryParams = new URLSearchParams(window.location.search)
    const referralIdentifier = queryParams.get('referral_identifier')
    setId(referralIdentifier)
  }, [id])

  return (
    <section css={style.parentContainer}>
      <div css={style.childContainer}>
        <Icon icon='crowdmouth' color='pink' css={style.logo} />
        <Title style={{ color: '#fff' }}>Log in to your account</Title>
        <LoginContainer
          rootStore={rootStore}
          handleSuccess={handleSuccess}
          id={id}
          handleClick={async () => {
            setIsLoading(true)
            const newUserData = await usersStore.api().creatorCreatorRole()
            if (newUserData.success) {
              authStore.setCurrentUser(newUserData.data)
              redirectPage()
            } else {
              logoutAndReload()
            }
          }}
        />
      </div>

      {!Boolean(redirectTo) && <DownloadAppModal />}
    </section>
  )
}

LoginPage.getInitialProps = async (ctx) => {
  Page.serverSideCall(ctx)

  return {
    props: {},
    redirectTo: ctx.query.redirect_to || null,
  }
}

export default inject('rootStore')(observer(LoginPage))

import { css } from '@emotion/react'
// import colors from '~/styles/colors'

export const downloadDiv = css`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem 0;
`

export const downloadImg = css`
  width: 90%;
`

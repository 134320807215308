import { css } from '@emotion/react'
import { mqMax } from 'styles/mixins/mediaQueries'

const PADDING = '1rem'

export const parentContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background-color: #2b2b2b;

  ${mqMax.sm} {
    height: auto;
  }
`

export const childContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100% - ${PADDING} * 2);
  height: auto;
  width: 28rem;
  padding: ${PADDING};
  box-sizing: border-box;
  text-align: center;

  ${mqMax.sm} {
    width: 100%;
    padding: ${PADDING} 0.5rem;
  }
`

export const logo = css`
  width: 16rem;
  display: block;
`

export const or = css`
  width: 90%;

  h3 {
    display: flex;
    flex-direction: row;
    color: #fff;
  }
  h3:before, h3:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }
  h3:before {
    margin-right: 10px
  }
  h3:after {
    margin-left: 10px
  }
`

export const socialButtons = css`
  display: flex;
  flex-direction: column;
  width: 90%;

  .social-login-button {
    padding: 5px 0;
    
    button {
      width: 100%;
      text-transform: none !important;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
    }
  }
`
